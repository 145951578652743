<template>
  <section
    v-if="item.plans"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        {{ t('Bảng giá') }}
      </h1>
      <p class="mb-2 pb-75">
        {{ t('Hãy chọn gói cước phù hợp với quy mô phòng và nhu cầu sử dụng tính năng của bạn.') }}
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6 class="mr-1 mb-0">
          {{ t('Theo tháng') }}
        </h6>
        <b-form-checkbox
          v-model="item.monthly"
          :value="false"
          :unchecked-value="true"
          switch
        />
        <h6 class="ml-50 mb-0">
          {{ t('Theo năm') }}
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col
            v-for="(plan,index) in item.plans"
            :key="index"
            :lg="4"
            :sm="6"
          >
            <b-card align="center">
              <!-- img -->
              <b-img
                v-if="plan.thumbnail"
                :src="plan.thumbnail"
                class="mb-2 mt-5"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3 class="text-truncate font-weight-bolder text-uppercase">{{ plan.name }}</h3>
              <!-- <b-card-text class="text-muted text-truncate">
                {{ plan.description }}
              </b-card-text> -->

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">

                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                  >{{
                    item.monthly
                      ? Number(plan.monthlyPrice > 0 ? plan.monthlyPrice : plan.monthlySlotPrice).toLocaleString()
                      : Number(plan.annualPrice > 0 ? plan.annualPrice : plan.annualSlotPrice).toLocaleString()
                  }}</span>
                  <sup
                    class="font-medium-1 font-weight-bold text-primary"
                  >đ</sup>
                  <sub v-if="(plan.monthlyPrice === 0 || plan.annualPrice === 0) && (plan.monthlySlotPrice > 0 || plan.annualSlotPrice > 0)" class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ t('phòng') }}</sub>
                </div>
                <small
                  v-show="!item.monthly && plan.annualSavedPercentage > 0"
                  class="annual-pricing text-secondary"
                  :style="{'text-decoration': 'line-through'}"
                >{{Number(plan.monthlyPrice > 0 ? plan.monthlyPrice * 12 : plan.monthlySlotPrice * 12).toLocaleString()}} đ</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <!-- <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data, index) in plan.benefits"
                  :key="index"
                  :class="`text-truncate ${!data.available && 'plan-item-unavailable'}`"
                >
                  {{ data.title }}
                </b-list-group-item>
              </b-list-group> -->
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                block
                class="mt-2"
                variant="outline-primary"
                :to="{ name: 'renew', params: { slug: plan.slug } }"
              >
                {{ t('Gia hạn') }}
              </b-button>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ t('Bạn cần hỗ trợ GIA HẠN?') }}
              </h3>
              <h5>
                {{ t('Hãy liên hệ ngay với chúng tôi qua hotline') }} <strong>035.543.0074</strong> {{ t('hoặc ấn vào nút') }} <strong>{{ t('Zalo hỗ trợ') }}</strong> {{ t('ở chân trang web nhé.') }}
              </h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                href="tel://0355430074"
              >
                {{ t('Gọi hotline') }}
              </b-button>
            </div>

            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />

          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <!-- <div class="pricing-faq">
      <h3 class="text-center">Câu hỏi thường gặp</h3>
      <p class="text-center">
        Những câu hỏi thường gặp của các chủ nhà khi thanh toán.
      </p>
      <b-row class="py-2">
        <b-col lg="10" offset-lg="2" class="mx-auto">
          <app-collapse accordion type="margin">
            <app-collapse-item
              v-for="(data, index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div> -->
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BCarousel,
  BCarouselSlide
} from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Ripple from 'vue-ripple-directive';
import useJwt from '@/auth/jwt/useJwt';
import usePricing from './usePricing';
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BCarousel,
    BCarouselSlide
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      item,
      isLoading,
      getPlans,
      tooglePlan,
      processPayment,
      t
    } = usePricing();

    return {
      item,
      isLoading,
      getPlans,
      tooglePlan,
      processPayment,
      t
    };
  },
  created() {
    this.getPlans();
  },

};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss">
  .annual-plan {
    margin-bottom: 1.0rem;
    padding-bottom: 1.0rem;
    .plan-price {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 2.5rem !important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }

    // annual pricing value
    .annual-pricing {
      font-size: 1.5rem !important;
      position: absolute;
      margin: auto;
      margin-top: 10px !important;
      left: 0;
      right: 0;
    }
  }
  .plan-item-unavailable {
    text-decoration: line-through;
  }
</style>
