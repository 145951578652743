import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import pricingStoreModule from './pricingStoreModule';

export default function useRoleList() {
  const STORE_MODULE_NAME = 'plan';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, pricingStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    plans: [],
    monthly: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const isLoading = ref(false);

  //   API Call
  const getPlans = () => {
    isLoading.value = true;
    store
      .dispatch('plan/getPlans')
      .then(response => {
        item.value.plans = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const tooglePlan = () => {
    item.value.monthly = !item.value.monthly;
  };

  const processPayment = plan => {
    store.dispatch('plan/createPaymentUrl', plan).then(response => {
      window.location.href = response.data;
    });
  };

  return {
    item,
    getPlans,
    isLoading,
    resetItem,
    tooglePlan,
    processPayment,
    t,
  };
}
